import { useEffect, useRef, useState } from "react";
import "../../Styles/so.scss";
import placeholder from "../../assets/imgs/placeholder.png";
import Input from "../Util/Input";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";

export default function NewSO(props) {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const nm = useRef();
  const phn = useRef();
  const eml = useRef();
  const gdr = useRef();
  const empno = useRef();
  const cat = useRef();
  const pwd = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Name: titleCase(nm.current.value),
      Phone: phn.current.value,
      Email: eml.current.value,
      Gender: gdr.current.value,
      EmployeeNo: empno.current.value,
      Category: cat.current.value,
      Password: pwd.current.value,
      Photo: selectedFile,
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      const chck = Object.values(body);
      let valid = true;
      chck.map((item) => {
        if (item == "") {
          valid = false;
        }
      });

      if (!valid) {
        setError("All fields are required!");
        return false;
      }
      if (body.Phone.length != 10) {
        setError("Invalid phone number!");
        return false;
      }
      if (body.Name.split(" ").length < 2) {
        setError("Provide at least 2 names!");
        return false;
      }

      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const i in body) {
        formData.append(i, body[i]);
      }
      setLoading(true);
      fetch(`/api/mobile/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/so";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFile !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="so">
      <div className="container">
        <div className="createorg">
          <div className="cpcontainer">
            <div className="tp">
              <h3>New Cleaner</h3>
              <h4
                onClick={() => {
                  window.location.href = "/so";
                }}
              >
                <i className="fa fa-arrow-right"></i> List
              </h4>
            </div>
            <form
              className="bdy"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
            >
              <div className="div2equal">
                <div>
                  <div className="section">
                    <h4>Cleaner Details</h4>
                    <hr />
                    <Input ref={nm} type="text" label="Name" />
                    <Input ref={phn} type="text" label="Phone *" />
                    <Select
                      ref={gdr}
                      data={["Male", "Female"]}
                      label="Gender *"
                    />
                  </div>
                </div>

                <div className="section">
                  <h4>Profile Photo (optional)</h4>
                  <hr />
                  <div className="preview">
                    <img src={imageSrc ? imageSrc : placeholder} alt="" />
                    <i onClick={handleIconClick} className="fa fa-camera"></i>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
              </div>
              <div className="section">
                <h4>Epmloyment Details</h4>
                <hr />
                <div className="div2equal">
                  <Input ref={empno} type="number" label="Employee Number *" />
                  <Select
                    ref={cat}
                    data={["Cleaner", "Supervisor"]}
                    label="Category *"
                  />
                </div>
              </div>

              <div className="section">
                <h4>Login Details</h4>
                <hr />
                <Input ref={eml} type="email" label="Email *" />
                <Input
                  ref={pwd}
                  type="password"
                  label="Password (Can be changed later) *"
                />
              </div>
              <h6>{error}</h6>
              <button
                onClick={() => {
                  createUser();
                }}
              >
                Submit
              </button>
            </form>
            {loading && <WaveLoading />}
          </div>
        </div>
      </div>
    </div>
  );
}
