import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import placeholder from "../../assets/imgs/placeholder.png";
import Input from "../Util/Input";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

export default function Organizations(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [download, setDowload] = useState(false);

  useEffect(() => {
    let url = `/api/organization/paginated/${offset}`;
    setLoading(true);
    setData(null);
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [offset, props.filter, refresh]);

  function searchOrganizationsh(v) {
    setLoading(true);
    setData(null);
    fetch(`/api/organization/searchall/${v}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        console.log(data);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="organization">
      <div className="container">
        <div className="topbar">
          <div
            onClick={() => {
              window.location.href = "/organizations/new";
            }}
            className="new"
          >
            <i className="fa fa-plus"></i>
            <p>Create Organization</p>
          </div>
          <div className="top">
            <i
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa fa-refresh"
            >
              &#xf021;
            </i>
            <i
              onClick={() => {
                if (data && data.data.length > 0) {
                  downloadCSV(data.data, "organizations.csv");
                }
              }}
              className="fa fa-download"
            ></i>

            <div className="search">
              <Input
                type="text"
                placeholder="Organization..."
                handleChange={(v) => {
                  if (v.length > 2) {
                    searchOrganizationsh(v);
                  }
                  if (v.length == 0) {
                    setRefresh(!refresh);
                  }
                }}
              />
              <i className="fa fa-search"></i>
            </div>
          </div>
        </div>
        <h4>Organizations</h4>
        <div className="olist">
          {data &&
            data?.data?.map((item, index) => {
              return (
                <Item
                  refresh={refresh}
                  setRefresh={setRefresh}
                  key={index}
                  page={offset}
                  index={index}
                  item={item}
                  url="auth"
                />
              );
            })}
        </div>
        {loading && <WaveLoading />}
        <Pagination
          totalItems={data?.total}
          currentPage={offset}
          onPageChange={setOffset}
        />
      </div>
    </div>
  );
}

const Item = (props) => {
  function deleteWP() {
    props.setLoading(true);
    fetch(`/api/organization/${props.item.OrgID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }

  return (
    <div className="soitem">
      <div className="update">
        <AiOutlineDelete
          onClick={() => {
            deleteWP();
          }}
          color="white"
          size={24}
          className="pencil"
        />
        <FiEdit
          onClick={() => {
            window.location.href = "/organizations/update/" + props.item.OrgID;
          }}
          color="white"
          size={24}
          className="pencil"
        />
      </div>
      <div className="title">
        <img
          src={
            props.item.Logo ? "/api/uploads/" + props.item.Logo : placeholder
          }
          alt=""
        />
        <div>
          <h4>{props.item.Organization}</h4>
          <p>
            {props.item.City}, {props.item.Address}
          </p>
        </div>
      </div>
      <h4>Cleaner Requirements</h4>
      <hr />
      <div className="div3equal">
        <p>
          <b>Day Shift: {props.item.SODay}</b>
        </p>
        <p>
          <b>Night Shift: {props.item.SONight}</b>
        </p>
        <p>
          <b>K-9 Units: {props.item.Supervisors}</b>
        </p>
      </div>

      <div>
        <h4>System Aministrator</h4>
        <hr />
        <p>
          <b>Name: </b> {props.item.Name}
        </p>
        <p>
          <b>Email: </b> {props.item.Email}
        </p>
        <p>
          <b>Phone: </b> {props.item.Phone}
        </p>
      </div>
    </div>
  );
};
