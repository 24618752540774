import { useEffect, useState } from "react";
import placeholder from "../../assets/imgs/placeholder.png";
import WaveLoading from "../Util/WaveLoading";
import Select from "../Util/Select";
import Pagination from "../Util/Pagination";
import { refresh } from "aos";

export default function SoWP(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const { getMonths } = require("../../assets/data/data.js");
  const yy = ["2023", "2024", "2025"];
  const [year, setYear] = useState("2023");
  const [offset, setOffset] = useState(0);
  const [today, setToday] = useState("");
  const [month, setMonth] = useState(null);
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    let x = [];
    const mi = getMonths
      .map((item) => {
        x.push(item.month);
        return item.code;
      })
      .indexOf(date[1]);

    getMonths.map((item) => {
      x.push(item.month);
    });
    setMM(x);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi != -1) {
      let x = [];
      const dtoday = parseInt(today.split("-")[2]);

      const size =
        getMonths[mi].days - dtoday < 12 ? getMonths[mi].days - dtoday : 12;
      for (
        let index = dtoday == 1 ? dtoday : dtoday - 1;
        index < size + dtoday + 1;
        index++
      ) {
        const day = `${year}-${mi + 1}-${index < 10 ? "0" + index : index}`;
        var date = new Date(day);
        var dayOfWeek = date.getDay();
        x.push({ day: dayNames[dayOfWeek], date: index, today: day });
      }
      if (x.length < 14) {
        const sz = 14 - x.length;
        for (let index = 0; index < sz; index++) {
          x.push({ day: "", date: "", today: "day" });
        }
      }
      console.log(x.length);
      setDD(x);
    }
  }, [month, year, today]);

  useEffect(() => {
    if (today != null) {
      setData(null);
      setLoading(true);
      fetch(`/api/mobile/paginated/${offset}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
          console.log(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [today, offset, props.refresh]);

  useEffect(() => {
    if (props.download && data && data?.data?.length > 0) {
      downloadCSV(data.data, "daily workplans.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  function addDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() + daysToAdd);
    return result.toISOString().split("T")[0];
  }

  function minusDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() - daysToAdd);
    return result.toISOString().split("T")[0];
  }

  return (
    <div className="sowp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>

      <div className="dlist">
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <SOItem
                key={i}
                index={i + offset}
                item={item}
                today={today}
                month={month}
                getMonths={getMonths}
              />
            );
          })}
      </div>
      <Pagination
        totalItems={data?.total}
        currentPage={offset}
        onPageChange={setOffset}
      />
      {loading && <WaveLoading />}
    </div>
  );
}

const DateItem = (props) => {
  return (
    <div
      onClick={() => {
        props.setToday(props.item.today);
      }}
      className={props.item.today === props.today ? "active" : "dateitem"}
    >
      <p>{props.item.date}</p>
      <h4>{props.item.day}</h4>
    </div>
  );
};

const SOItem = (props) => {
  const [data, setData] = useState(null);
  const [color, setColor] = useState("");
  const [days, setDays] = useState("0");
  const [status, setStatus] = useState("Under Assigned");

  useEffect(() => {
    const v = props.index % 2;
    switch (v) {
      case 0:
        setColor("#F2ECBE");
        break;
      default:
        setColor("#E5CFF7");
        break;
    }
    console.log(props.item);
    fetch(`/api/workplan/byuserid/${props.item.UserID}/${props.today}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [props.item]);

  useEffect(() => {
    if (data) {
      const date = new Date(props.today).toISOString().split("T")[0].split("-");
      const mi = props.getMonths
        .map((item) => {
          return item.code;
        })
        .indexOf(date[1]);
      setDays(props.getMonths[mi].days);
      if (data.length == props.getMonths[mi].days) {
        setStatus("Okay");
      } else setStatus("Under Assigned");
    }
  }, [data]);

  return (
    <div style={{ backgroundColor: color }} className="soitem">
      <div className="title">
        <div className="soindex">
          <h2>{props.index + 1}</h2>
        </div>
        <div className="div2equal">
          <div>
            <h4>{props.item.Name}</h4>
            <p>
              <b>Phone: </b> {props.item.Phone}
            </p>
            <p>
              <b>Gender: </b> {props.item.Gender}
            </p>
          </div>
          <div>
            <h4>SO Details</h4>
            <hr />
            <div className="div2equal">
              <p>
                <b>Category: </b> {props.item.Category}
              </p>
              <p>
                <b>Employee No: </b> {props.item.EmployeeNo}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: status == "Okay" ? "green" : "red" }}
        className="status"
      >
        <h6>{status}</h6>
        <h6>
          Total: {data ? data?.length : 0}/{days}
        </h6>
      </div>

      <h4>{props.month} Work Plan</h4>
      <hr />
      {data &&
        data.length > 0 &&
        data.map((item, i) => {
          return <WPDate key={i} index={i} item={item} />;
        })}
    </div>
  );
};

const WPDate = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#FFF8C9");

  useEffect(() => {
    console.log(props.item.Reason);
    switch (props.item.Reason) {
      case "Normal":
        setColor("#FFF8C9");
        break;
      case "Reliever":
        setColor(colors[0]);
        break;
      case "Off Day":
        setColor(colors[1]);
        break;
      case "Sick Leave":
        setColor(colors[2]);
      case "Leave":
        setColor(colors[3]);
        break;
      case "AWOL":
        setColor(colors[4]);
        break;
      default:
        break;
    }
  }, [props.item]);
  return (
    <div style={{ backgroundColor: color }} className="sofficer">
      <div className="div4equal">
        <div className="index">
          <h2>{props.item.Date}</h2>
        </div>
        <div>
          <h4>{props.item.Reason}</h4>
          <p>
            <b>Assignment: </b> {props.item.Organization},{props.item.City},{" "}
            {props.item.Address}
          </p>
        </div>
        <div>
          <h4>Cleaner Details</h4>
          <hr />
          <p>
            <b>Employee No: </b>
            {props.item.EmployeeNo}
          </p>
        </div>
        <div>
          <h4>Shift Details</h4>
          <hr />
          <p
            style={{
              backgroundColor: props.item.Shift == "Day" ? "orange" : "brown",
              padding: "2px",
              color: "white",
            }}
          >
            <b>Shift: </b>
            {props.item.Shift}
          </p>
        </div>
      </div>
    </div>
  );
};
