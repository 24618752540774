import "../../Styles/resources.scss";
import { useEffect, useState } from "react";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
import Select from "../Util/Select.jsx";

export default function Resources(props) {
  const { getMonths } = require("../../assets/data/data.js");
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [start, setStart] = useState("");
  const [year, setYear] = useState("2023");
  const [end, setEnd] = useState("");
  const [offset, setOffset] = useState(0);
  const [mm, setMM] = useState([]);
  const [month, setMonth] = useState(null);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    let x = [];
    const mi = getMonths
      .map((item) => {
        x.push(item.month);
        return item.code;
      })
      .indexOf(date[1]);

    getMonths.map((item) => {
      x.push(item.month);
    });
    setMM(x);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    if (month != "" && year != "") {
      getData();
    }
  }, [month, year, offset]);

  useEffect(() => {
    if (start != "" && end != "") {
      getData();
    }
  }, [start, end]);

  function getData() {
    setData(null);
    setLoading(true);
    fetch(
      `/api/resources/paginated/filtered/all/${month}/${year}/${offset * 12}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;" +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Resources.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="resources">
      <div className="list">
        <div className="div1auto">
          <h4>Cleaning Resources</h4>

          <i
            onClick={() => {
              if (data) {
                saveData(data.data);
              }
            }}
            className="fa fa-download download"
          ></i>
        </div>
        <div className="month">
          <button
            onClick={() => {
              window.location.href = "/resources/new";
            }}
          >
            Add New
          </button>
          <Select
            setChanged={(v) => {
              setMonth(v);
            }}
            data={mm}
            value={month}
          />
          <Select
            setChanged={(v) => {
              setYear(v);
            }}
            value={year}
            data={["2023", "2024", "2025", "2026"]}
          />
        </div>
        <div className="data">
          <div className="head">
            <h4>No.</h4>
            <h4>Customer</h4>
            <h4>Supervisor</h4>
            <h4>Month</h4>
            <h4>Item</h4>
            <h4>Units</h4>
            <h4>Ratio</h4>
            <h4>Qty</h4>
            <h4>Price</h4>
            <h4>Total</h4>
          </div>
          {data &&
            data.data.length > 0 &&
            data.data.map((item, i) => {
              return <Item key={i} item={item} index={i} />;
            })}
        </div>
        {loading && <Loading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  const [values, setValues] = useState(null);
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setClicked(true);
        }}
        className="item"
      >
        <p>{props.index + 1}</p>
        <p>{props.item.Customer}</p>
        <p>{props.item.UserID}</p>
        <p>{props.item.Month}</p>
        <p>{props.item.Item}</p>
        <p>{props.item.Units}</p>
        <p>{props.item.Ratio}</p>
        <p>{props.item.Quantity}</p>
        <p>{props.item.Price}</p>
        <p>{props.item.Total}</p>
      </div>
      {clicked && (
        <Popup item={props.item} setClicked={setClicked} index={props.index} />
      )}
    </>
  );
};

const Popup = (props) => {
  const [values, setValues] = useState(null);
  const [cols, setCols] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.item) {
      let ds = props.item;
      delete ds.geom;
      setData([ds]);
      let dt = props.item;
      delete dt.geom;
      delete dt.ID;
      delete dt.id;

      const v = Object.values(dt);
      const c = Object.keys(dt);
      setValues(v);
      setCols(c);
    }
  }, [props.item]);

  const DItem = (params) => {
    return (
      <div className="ditem">
        <h4>{params.column}</h4>
        <p>{params.value}</p>
      </div>
    );
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="popup">
      <div className="container">
        <div className="div1auto">
          <h4>Data Item {props.index}</h4>

          <i
            onClick={() => {
              props.setClicked(false);
            }}
            style={{ color: "white" }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="data">
          <div className="div2equal">
            {cols &&
              cols.map((c, i) => {
                return <DItem key={i} column={c} value={values[i]} />;
              })}
          </div>
        </div>
        <button
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
        >
          Download Record
        </button>
      </div>
    </div>
  );
};
