import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  ComposedChart,
  Area,
} from "recharts";

export default function CombinedBarChart(props) {
  return (
    <div className="chrt">
      {props.data && (
        <ResponsiveContainer width={"98%"} aspect={props.aspect}>
          <BarChart
            data={props.data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Cleaner" stackId="a" fill="#0577B3" />
            <Bar dataKey="Supervisor" stackId="a" fill="#6B275A" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
