import { useRef, useState, useEffect } from "react";
import Input from "../Util/Input";
import Select from "../Util/Select";
import "../../Styles/workplan.scss";
import WaveLoading from "../Util/WaveLoading";

export default function CreateNew(props) {
  const { getMonths } = require("../../assets/data/data.js");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [total, setTotal] = useState(0);
  const [su, setSU] = useState(null);
  const [sudata, setSUData] = useState(null);
  const ite = useRef();
  const rat = useRef();
  const qty = useRef();
  const unit = useRef();
  const tot = useRef();
  const units = useRef();
  var jwt = require("jsonwebtoken");
  const [body, setBody] = useState({
    UserID: null,
    Customer: null,
    Item: null,
    Month: null,
    Quantity: null,
    Ratio: null,
    Price: null,
    Total: null,
    Units: null,
  });
  const [showing, setShowing] = useState(true);
  const [mm, setMM] = useState([]);
  const [month, setMonth] = useState(null);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    let x = [];
    const mi = getMonths
      .map((item) => {
        x.push(item.month);
        return item.code;
      })
      .indexOf(date[1]);

    getMonths.map((item) => {
      x.push(item.month);
    });
    setMM(x);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
      let d = body;
      d.Month = getMonths[mi].month;
      setBody(d);
    }
  }, []);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("fmgdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          let d = body;
          d.UserID = decoded.Name;
          setBody(d);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  function searchByPhone(role, q) {
    if (role === "Organization") {
      fetch(`/api/organization/search/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setSU(data);
        })
        .catch((e) => {});
    } else {
      fetch(`/api/mobile/seachbyphone/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setFO(data);
        })
        .catch((e) => {});
    }
  }

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Item = ite.current.value;
    d.Quantity = qty.current.value;
    d.Ratio = rat.current.value;
    d.Price = unit.current.value;
    d.Total = tot.current.value;
    setBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/resources/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          d.Customer =
            params.item.Organization +
            ", " +
            params.item.City +
            ", " +
            params.item.Address;
          setBody(d);
          setSUData(params.item);
          setSU(null);
        }}
        className="so_list"
      >
        <h2>{params.item.Organization} </h2>
        <h5>
          {params.item.City}, {params.item.Address}
        </h5>
      </div>
    );
  };

  return (
    <div className="workplan">
      <div className="container">
        <div className="createworkplan">
          <div className="cpcontainer">
            <div className="tp">
              <h3>Add Resource</h3>
              <h4
                onClick={() => {
                  window.location.href = "/resources";
                }}
              >
                <i className="fa fa-arrow-right"></i> Resources
              </h4>
            </div>
            <div className="bdy">
              <div className="section">
                <h4>Search Organization</h4>
                <hr />
                <div className="div2equal">
                  <div className="search">
                    <br />
                    <Input
                      type="text"
                      handleChange={(e) => {
                        searchByPhone("Organization", e);
                      }}
                      label="Name"
                      hint="Type to search"
                    />
                    {su && (
                      <div className="search_list">
                        {su.map((item, i) => {
                          return <List key={i} item={item} type="su" />;
                        })}
                      </div>
                    )}
                  </div>

                  <div>
                    {sudata && (
                      <Item
                        title="Selected Organization"
                        clearEvent={() => {
                          let d = body;
                          d.Customer = null;
                          setBody(d);
                          setSUData(null);
                        }}
                        data={sudata}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="section">
                <h4>Resource Details</h4>
                <hr />
                <Input type="text" label="Item Name" ref={ite} />
                <div className="div3equal">
                  <Select
                    setChanged={(v) => {
                      let d = body;
                      d.Month = v;
                      setBody(d);
                    }}
                    data={mm}
                    value={month}
                    label="Month"
                  />
                  <Select
                    data={["Lts", "Kg", "Barrel", "Sack", "Number", "Other"]}
                    label="Units"
                    ref={units}
                    setChanged={(e) => {
                      let d = body;
                      d.Units = e;
                      setBody(d);
                    }}
                  />
                  <Input type="number" label="Ratio" ref={rat} />
                </div>
                <div className="div3equal">
                  <Input
                    handleChange={(e) => {
                      let x = unit.current.value;
                      if (x != "" && e != "") {
                        setTotal(x * e);
                      }
                    }}
                    type="number"
                    label="Quantity"
                    ref={qty}
                  />
                  <Input
                    handleChange={(e) => {
                      let x = qty.current.value;
                      if (x != "" && e != "") {
                        setTotal(x * e);
                      }
                    }}
                    type="number"
                    ref={unit}
                    label="Unit Price"
                  />
                  <Input
                    type="number"
                    value={total}
                    ref={tot}
                    label="Total Price"
                  />
                </div>
              </div>

              <h6>{error}</h6>

              <button
                onClick={() => {
                  createDocument();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>{props.data.Organization}</p>
          <p>
            {props.data.City}, {props.data.Address}
          </p>
        </div>
      )}
    </>
  );
};

const SOItem = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>Name: {props.data.Name}</p>
          <p>Employee No:{props.data.EmployeeNo}</p>
          <p>Category: {props.data.Category}</p>
        </div>
      )}
    </>
  );
};
