import { useRef, useState, useEffect } from "react";
import "../../Styles/organizations.scss";
import Input from "../Util/Input";
import placeholder from "../../assets/imgs/placeholder.png";
import WaveLoading from "../Util/WaveLoading";

export default function UpdateOrg(props) {
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    Organization: null,
    Name: null,
    Phone: null,
    Email: null,
    SODay: null,
    SONight: null,
    Supervisors: null,
    Address: null,
    City: null,
  });
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const org = useRef();
  const nm = useRef();
  const phn = useRef();
  const eml = useRef();
  const lg = useRef();
  const sod = useRef();
  const son = useRef();
  const dg = useRef();
  const addr = useRef();
  const cty = useRef();
  const pwd = useRef();
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    fetch(`/api/organization/${pathname[3]}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        let d = { ...body };
        d.Organization = data?.Organization;
        d.Name = data?.Name;
        d.Phone = data?.Phone;
        d.Email = data?.Email;
        d.SODay = data?.SODay;
        d.SONight = data?.SONight;
        d.Supervisors = data?.Supervisors;
        d.Address = data?.Address;
        d.City = data?.City;
        d.Logo = data?.Logo;
        setBody(d);
        console.log(body);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (body.Logo != null) {
      fetch(`/api/uploads/${body.Logo}`)
        .then((res) => {
          if (res.ok) return res.blob();
          else throw Error("");
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            setImageSrc(reader.result);
          };
          reader.readAsDataURL(blob);
        })
        .catch((e) => {});
    }
  }, [body.Logo]);

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    let d = { ...body };
    d.Organization = titleCase(org.current.value);
    d.Name = titleCase(nm.current.value);
    d.Phone = phn.current.value;
    d.Email = eml.current.value;
    d.SODay = sod.current.value;
    d.SONight = son.current.value;
    d.Supervisors = dg.current.value;
    d.Address = addr.current.value;
    d.City = cty.current.value;
    if (selectedFile != null) d.Logo = selectedFile;
    setBody(d);

    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(d.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      const chck = Object.values(d);
      let valid = true;
      chck.map((item) => {
        if (item === null || item == "") {
          valid = false;
        }
      });
      if (d.Logo === null)
        return setError(
          "Logo is required! (upload a placeholder is not available)"
        );
      if (!valid) {
        setError("All fields are required!");
        return false;
      }
      if (d.Phone.length != 10) {
        setError("Invalid phone number!");
        return false;
      }
      if (d.Name.split(" ").length < 2) {
        setError("Provide at least 2 names!");
        return false;
      }

      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const i in d) {
        formData.append(i, d[i]);
      }
      setLoading(true);
      fetch(`/api/organization/${pathname[3]}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/organizations";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFile !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        let d = body;
        d.Logo = selectedFile;
        setBody(d);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="organization">
      <div className="container">
        <div className="createorg">
          <div className="cpcontainer">
            <div className="tp">
              <h3>Update Organization</h3>
              <h4
                onClick={() => {
                  window.open("/orgs/List");
                }}
              >
                <i className="fa fa-arrow-right"></i> List
              </h4>
            </div>
            <form
              className="bdy"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
            >
              <div className="div2equal">
                <div>
                  <div className="section">
                    <h4>Organization Details</h4>
                    <hr />
                    <Input
                      ref={org}
                      type="text"
                      value={body?.Organization && body?.Organization}
                      label="Organization Name"
                    />
                    <Input
                      ref={cty}
                      type="text"
                      value={body?.City}
                      label="City *"
                    />
                    <Input
                      ref={addr}
                      value={body?.Address}
                      type="text"
                      label="Address (area, street, building)*"
                    />
                  </div>
                </div>

                <div className="section">
                  <h4>Organization Logo</h4>
                  <hr />
                  <div className="preview">
                    <img src={imageSrc ? imageSrc : placeholder} alt="" />
                    <i onClick={handleIconClick} className="fa fa-camera"></i>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
              </div>
              <div className="section">
                <h4>Cleaners' Requirements</h4>
                <hr />
                <div className="div3equal">
                  <Input
                    ref={sod}
                    value={body?.SODay}
                    type="number"
                    label="Number of day shift cleaners *"
                  />
                  <Input
                    ref={son}
                    type="number"
                    label="Number of night cleaners *"
                    value={body?.SONight}
                  />
                  <Input
                    ref={dg}
                    type="number"
                    label="Number of supervisors *"
                    value={body?.Supervisors}
                  />
                </div>
              </div>

              <div className="section">
                <h4>Organization's System Administrator</h4>
                <hr />
                <div className="div2equal">
                  <Input
                    ref={nm}
                    type="text"
                    label="Name (At least two names)*"
                    value={body?.Name}
                  />
                  <Input
                    ref={phn}
                    type="number"
                    label="Phone *"
                    value={body?.Phone}
                  />
                </div>

                <Input
                  ref={eml}
                  type="email"
                  label="Email *"
                  value={body?.Email}
                />
              </div>
              <h6>{error}</h6>
              <button
                onClick={() => {
                  createUser();
                }}
              >
                Submit
              </button>
            </form>
            {loading && <WaveLoading />}
          </div>
        </div>
      </div>
    </div>
  );
}
