import { useEffect, useState } from "react";
import "../../Styles/gis.scss";
// openlayers
import Map from "ol/Map";
import View from "ol/View";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import GeoJSON from "ol/format/GeoJSON";
import Graticule from "ol/layer/Graticule";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { useRef } from "react";
import RippleLoading from "../Util/RippleLoading";
import WaveLoading from "../Util/WaveLoading";
import Basemaps from "./Basemaps";
import RightPanel from "./RightPanel";
// import Popup from "./Popup";

export default function GIS(props) {
  const { myData } = require("../../assets/data/data.js");
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waveLoading, setWaveLoading] = useState(false);
  const [showing, setShowing] = useState([]);
  const [visualization, setvisualization] = useState("Normal");
  const [legendStyle, setLegendStyle] = useState([]);
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  const [selected, setSelected] = useState(0);
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [data, setData] = useState([]);
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [popup, setPopup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [baseSelector, setBaseSelector] = useState(null);
  const [extent, setExtent] = useState([
    4185121.788385366, -342263.56009544665, 4348867.3136286605,
    -6199.27329767576,
  ]);
  //set initial state
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;

  useEffect(() => {
    basemap.setSource(
      new XYZ({
        url: myData[0].url,
        crossOrigin: "anonymous",
      })
    );
    const initialMap = new Map({
      target: mapElement.current,
      layers: [basemap, graticule],
      view: new View({
        projection: "EPSG:4326",
        center: [36.45, -0.1],
        zoom: 12,
        maxZoom: 20,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [34.36168, 0.41839, 35.06887, 1.14702],
        }),
        new ScaleLine({
          units: "metric",
          bar: false,
          text: "Scale",
        }),
      ]),
    });

    initialMap.forEachFeatureAtPixel(() => {});

    initialMap.on("click", function (ev) {
      initialMap.forEachFeatureAtPixel(ev.pixel, function (feature) {
        setPopup(feature.values_);
        return true;
      });
    });

    setMap(initialMap);
    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (map) {
      fetch(`/api/tracker`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          if (data.length > 0) {
            let pts = [];
            data.map((e) => {
              pts.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [e.Longitude, e.Latitude],
                },
                properties: e,
              });
            });
            console.log(pts);
            let geojsonObject = {
              type: "FeatureCollection",
              features: pts,
            };
            const vectorSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojsonObject),
            });

            const vectorLayer = new VectorLayer({
              source: vectorSource,
              style: new Style({
                image: new CircleStyle({
                  radius: 3,
                  fill: new Fill({
                    color: "rgba(255, 0, 0, 0.6)",
                  }),
                  stroke: new Stroke({
                    color: "#319FD3",
                    width: 1,
                  }),
                }),
              }),
            });

            map.addLayer(vectorLayer);

            const extent = vectorSource.getExtent();
            map.getView().fit(extent, { padding: [50, 50, 50, 50] });

            return () => {
              map.setTarget(null);
            };
          }
        })
        .catch(() => {});
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [props.fullscreen]);

  function removeByTitle(title) {
    map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get("title") === title)
      .forEach((layer) => map.removeLayer(layer));
  }

  const saveMap = () => {
    map.once("rendercomplete", function () {
      const mapCanvas = document.createElement("canvas");
      const size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext("2d");
      Array.prototype.forEach.call(
        map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      var link = document.createElement("a");
      link.setAttribute("href", mapCanvas.toDataURL());
      link.setAttribute("download", "map.png");
      document.body.appendChild(link);
      link.click();
    });
    map.renderSync();
  };

  const saveData = () => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "mapdata.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  updateMapSize();

  function updateMapSize() {
    if (map) {
      map?.updateSize();
    }
  }

  function resetMap() {
    if (map) {
      map.getView().fit(ward.getSource().getExtent());
    }
  }

  return (
    <div ref={mapRef} className="gis">
      <div className="map">
        <div
          className="map-element"
          style={{
            height: "100%",
            width: "100%",
          }}
          ref={mapElement}
        ></div>
        <div className="download">
          <div>
            <a
              onClick={() => {
                saveData();
              }}
              role="button"
            >
              <i className="fa fa-download"></i>Data
            </a>
          </div>
          <div>
            <a
              onClick={() => {
                saveMap();
              }}
              role="button"
            >
              <i className="fa fa-download"></i>Map
            </a>
          </div>
        </div>

        {popup && <Popup data={popup} setPopup={setPopup} />}
        {loading && <RippleLoading />}
        {waveLoading && <WaveLoading />}
        {isLoading && <RippleLoading />}
        <div
          onClick={() => {
            setBaseSelector(true);
          }}
          className="base_selector"
        >
          <i className="fa fa-map"></i>
        </div>

        {baseSelector && (
          <Basemaps
            setBaseSelector={setBaseSelector}
            basemap={basemap}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        <RightPanel
          map={map}
          setData={setData}
          setIsLoading={setIsLoading}
          setExtent={setExtent}
          setFilter={setFilter}
          filter={filter}
          resetMap={resetMap}
          showing={props.showing}
          removeByTitle={removeByTitle}
        />
      </div>
    </div>
  );
}

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);

    const cols = Object.keys(props.data);
    let d = [];
    cols.forEach((item) => {
      if (item.toLowerCase() !== "geometry") {
        d.push(item);
      }
    });
    setCols(d);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (data) {
      const cols = Object.keys(data[index]);
      let d = [];
      cols.forEach((item) => {
        if (item.toLowerCase() !== "geom") {
          d.push(item);
        }
      });
      setCls(d);
    }
  }, [index]);

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>SO Walking Speed: {props?.data?.Speed.toFixed(3)} KPH</h3>
          <i
            onClick={() => {
              props.setPopup(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="content">
          {cols &&
            cols.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {props.data[item]}{" "}
                </p>
              );
            })}
          {cls &&
            cls.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {data[index][item]}
                </p>
              );
            })}
          {isLoading && <WaveLoading />}
          <div className="tally">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return (
                  <p
                    className={i === index ? "active" : ""}
                    onClick={() => {
                      setIndex(i);
                    }}
                    key={i}
                  >
                    {i + 1}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
