import { useEffect, useRef, useState } from "react";
import "../../Styles/workplan.scss";
import Reports from "./MonthWP";
import DailyWP from "./DailyWP";
import Input from "../Util/Input";
import Select from "../Util/Select";
import MonthWP from "./MonthWP";
import OrgWP from "./OrgWP";
import SoWP from "./SoWP";

export default function WorkPlan(props) {
  const [active, setActive] = useState("Daily View");
  const [refresh, setRefresh] = useState(false);
  const [download, setDownload] = useState(false);

  return (
    <div className="workplan">
      <div className="container">
        <div className="topbar">
          <div
            onClick={() => {
              window.location.href = "/wp/new";
            }}
            className="new"
          >
            <i className="fa fa-plus"></i>
            <p>Create Work Plan</p>
          </div>
          <div className="top">
            <i
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa fa-refresh"
            >
              &#xf021;
            </i>
            <i
              onClick={() => {
                setDownload(true);
              }}
              className="fa fa-download"
            ></i>

            <TopItem active={active} setActive={setActive} txt="Daily View" />
            <TopItem active={active} setActive={setActive} txt="Month View" />
            <TopItem
              active={active}
              setActive={setActive}
              txt="Organization View"
            />
            <TopItem active={active} setActive={setActive} txt="Cleaner View" />
          </div>
        </div>

        {active === "Daily View" && (
          <DailyWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
        {active === "Month View" && (
          <MonthWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
        {active === "Organization View" && (
          <OrgWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
        {active === "Cleaner View" && (
          <SoWP
            refresh={refresh}
            setRefresh={setRefresh}
            download={download}
            setDownload={setDownload}
          />
        )}
      </div>
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      className={props.txt === props.active ? "active" : "topitem"}
      onClick={() => {
        props.setActive(props.txt);
      }}
    >
      <p>{props.txt}</p>
    </div>
  );
};
